import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { View, Image, Text, TouchableOpacity, TouchableHighlight, Platform } from 'react-native';
import Cookies from 'js-cookie';

import Routing from '../../wrappers/Router/Router';
import Actions from '../../RouteActions';
import { getCurrentOrganizationForUser, getCurrentOrganizationAccessForUser } from '../../helperFunctions';
import { openReferFormDrawer, openSettingsDrawer } from '../../DrawerActions.js';
import { getOrganizationsList } from '../../globalActions/organizationsActions';
import { getCurrentUser } from '../../screens/Browse/actions';
import { dashboardUrls, browseUrls, trackerUrls, myUrls } from '../../urls.js';
import newMemberAppUrl from '../../services/newMemberAppUrl';
import OrganizationStatus from '../../wrappers/organizationStatus/organizationStatus';
import * as actions from './actions';
import { logout } from '../../screens/Settings/actions';
// styles
import styles from './styles';

//icons
import ShieldImg from '../../assets/img/shield_icon_grey.png';
import LogoutImg from '../../assets/svg/logout.svg';
import HomeIcon from '../../assets/tabsIcons/HomeIcon/HomeIcon';
import BrowseIcon from '../../assets/tabsIcons/BrowseIcon/BrowseIcon';
import TrackerIcon from '../../assets/tabsIcons/TrackerIcon/TrackerIcon';
import MyAccountIcon from '../../assets/tabsIcons/MyAccountIcon/MyAccountIcon';
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';
export class SideBar extends PureComponent {
  clearLocalStorage = () => {
    localStorage.removeItem('fromTracker');
    localStorage.removeItem('userId');
    localStorage.removeItem('subjectEmail');
  };

  componentDidMount() {
    this.props.getOrganizationsList(this.props.setShouldChangeOrg);
    this.props.getCurrentUser(false);
    window.addEventListener('beforeunload', this.clearLocalStorage);
    const env = process.env.REACT_APP_ENVIRONMENT || 'development';
    const currentOrgIdentifier = Cookies.get(`currentOrgIdentifier-${env}`);
    const { currentSelectedOrg, organizations } = this.props;
    const currOrgId = currentSelectedOrg.id;
    const selectedOrgObj = organizations.find((org) => org.organization.id === currOrgId);
    const selectedOrgIdentifier = selectedOrgObj ? selectedOrgObj.general_setting_identifier : null;
    if (selectedOrgIdentifier && (!currentOrgIdentifier || currentOrgIdentifier !== selectedOrgIdentifier)) {
      Cookies.set(`currentOrgIdentifier-${env}`, selectedOrgIdentifier, {
        domain: env === 'development' ? 'localhost' : '.goboon.co',
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.clearLocalStorage);
  }

  tabs = [
    {
      key: 'home',
      renderIcon: (style_arr, active) => (
        <HomeIcon containerStyle={styles.navLinkContainer} textStyle={style_arr} isWhiteLogo={false} focused={active} />
      ),
      action: () => Actions.userAppDashboard(),
      activePaths: Object.values(dashboardUrls),
    },
    {
      key: 'browse',
      renderIcon: (style_arr, active) => (
        <BrowseIcon
          containerStyle={styles.navLinkContainer}
          textStyle={style_arr}
          isWhiteLogo={false}
          focused={active}
        />
      ),
      action: () => Actions.userAppBrowse(),
      activePaths: Object.values(browseUrls).filter((url) => url !== '/:companyName/browse'),
    },
    {
      key: 'tracker',
      renderIcon: (style_arr, active) => (
        <TrackerIcon
          containerStyle={styles.navLinkContainer}
          textStyle={style_arr}
          isWhiteLogo={false}
          focused={active}
        />
      ),
      action: () => Actions.userAppTracker(),
      activePaths: Object.values(trackerUrls),
    },
    {
      key: 'my_account',
      renderIcon: (style_arr, active) => (
        <MyAccountIcon
          containerStyle={styles.navLinkContainer}
          textStyle={style_arr}
          isWhiteLogo={false}
          focused={active}
        />
      ),
      action: () => window.open(`${newMemberAppUrl}/account/profile`, '_blank'),
      activePaths: Object.values(myUrls),
    },
  ];

  renderTab = (tabData) => {
    const selected = tabData.activePaths.some((path) => {
      const staticPath = path.split(':')[0];
      return this.props.currentPath.indexOf(staticPath) === 0;
    });
    const style_arr = selected ? [styles.textStyle, styles.selectedPage] : [styles.textStyle, styles.unSelectedPage];

    const handleTabPress = () => {
      this.clearLocalStorage();
      tabData.action();
    };

    return (
      <View style={[styles.linkWrapper, tabData.key === 'my_account' ? { marginBottom: 10 } : null]} key={tabData.key}>
        <TouchableOpacity onPress={handleTabPress} style={[styles.navLink, selected && styles.activeNavLink]}>
          {tabData.renderIcon(style_arr, selected)}
        </TouchableOpacity>
      </View>
    );
  };

  handleOrgChange = (item) => {
    if (this.props.currentSelectedOrg.id !== item[0].id) {
      this.props.setCurrentOrganization(item[0]);
    }
  };

  checkURL = (org) => {
    if (org.label !== 'Boon') {
      return window.open(
        `${this.props.adminAppUrl}/dashboard?tab=''&orgUuid=${
          this.props.currentSelectedOrg.uuid || this.props.currentSelectedOrg.id
        }`,
        '_self'
      );
    }
    return window.open(`${this.props.adminAppUrl}/dashboard`, '_self');
  };

  render() {
    const { currentUserOrganization, organizations, currentSelectedOrg, boonView } = this.props;
    let userOrgs = organizations.map((org) => {
      return {
        label: org.organization.name,
        value: org.organization.name,
        id: org.organization.id,
        uuid: org.organization.uuid,
        name: org.organization.name,
        logo: org.organization.logo_image_thumb,
        isUsHeadquarter: org.organization.based_in_us_headquarter,
      };
    });

    if (boonView) {
      userOrgs = [
        ...userOrgs,
        {
          label: 'Boon',
          value: 'Boon',
          id: null,
          logo: 'https://boon-co-app.s3.amazonaws.com/static/media/boon-image.png',
        },
      ];
    }

    if (!userOrgs.length) {
      userOrgs = [
        {
          label: 'Boon',
          value: 'Boon',
          id: null,
          logo: 'https://boon-co-app.s3.amazonaws.com/static/media/boon-image.png',
        },
      ];
    }

    if (!currentSelectedOrg && userOrgs.length) {
      this.props.doLogout();
    }

    return (
      window.top === window.self && (
        <View style={styles.sideMenu} keyboardShouldPersistTaps="handled">
          <View style={[styles.navMenuWrapper, { zIndex: 10 }]} nativeID="org-wrapper">
            <Image
              source={
                currentSelectedOrg.label == 'Boon'
                  ? 'https://boon-co-app.s3.amazonaws.com/static/media/boon-image.png'
                  : currentSelectedOrg && currentSelectedOrg.logo
                  ? currentSelectedOrg.logo
                  : defaultOrgAvatar
              }
              style={styles.image}
            />
            {userOrgs.length > 1 && (
              <OrganizationStatus
                onChange={(e) => this.handleOrgChange(e)}
                selected={currentSelectedOrg.label} //max 11 chars
                searchable={false}
                name="org_changer"
                dropdownValues={userOrgs}
                styles={styles.dropdownStyle}
                labelField={'label'}
                valueField={'value'}
                dropdownStyles={{
                  border: 'none',
                  fontFamily: 'Roboto-Regular',
                  fontSize: 13,
                  color: '#9B9B9B',
                  width: 120,
                  height: 24,
                  paddingTop: 5,
                }}
              />
            )}
            {userOrgs.length === 1 && <Text style={styles.orgName}>{userOrgs[0].label}</Text>}
          </View>

          <View style={[styles.referWrapper, styles.actions]}>
            <TouchableHighlight
              style={[styles.refer, styles.button]}
              onPress={() =>
                this.props.openReferDrawer({
                  organization_id: currentUserOrganization,
                })
              }
            >
              <View
                style={[
                  styles.elementWrapper,
                  styles.referTextWrapper,
                  Platform.OS === 'web' ? { boxShadow: '0px 2px 7px rgba(116, 113, 113, 0.317499)' } : {},
                ]}
              >
                <Text style={[styles.textStyle, styles.referText]}>New Referral</Text>
              </View>
            </TouchableHighlight>
          </View>
          <View>{this.tabs.map((tab) => this.renderTab(tab))}</View>

          {this.props.isAnyOrgAdmin && (
            <View style={[styles.adminPanel]}>
              <TouchableOpacity onPress={() => this.checkURL(currentSelectedOrg)} style={styles.linkCont}>
                <Image style={styles.linkIcon} source={ShieldImg} />
                <Text style={styles.linkTitle}>Admin App</Text>
              </TouchableOpacity>
            </View>
          )}
          <View style={styles.logoutCont}>
            <TouchableOpacity onPress={this.props.doLogout} style={styles.logoutLinkCont}>
              <Image style={styles.logoutIcon} source={LogoutImg} />
              <Text style={styles.linkTitle}>Logout</Text>
            </TouchableOpacity>
          </View>
        </View>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const organizationsWhereImMember = state.organizationsReducer.userOrganizations.filter((organization) => {
    return organization.role !== null && organization.status === 1;
  });
  return {
    organizations: organizationsWhereImMember,
    currentUserOrganization: getCurrentOrganizationForUser(state),
    currentUser: state.authReducer.currentUser,
    isAnyOrgAdmin: getCurrentOrganizationAccessForUser(state),
    adminAppUrl: state.authReducer.adminAppUrl,
    currentSelectedOrg: state.authReducer.currentSelectedOrg,
    boonView: state.organizationsReducer.boonView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openReferDrawer: (initialValues) => {
      dispatch(openReferFormDrawer(initialValues));
    },
    openSettingsDrawer: () => {
      dispatch(openSettingsDrawer());
    },
    setCurrentOrganization: (org) => {
      dispatch(actions.setCurrentOrg(org));
    },
    getOrganizationsList: (setShouldChangeOrg) => {
      dispatch(getOrganizationsList(setShouldChangeOrg));
    },
    getCurrentUser: (redirect) => {
      dispatch(getCurrentUser(redirect));
    },
    doLogout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
