import history from "./RouteHistory";
import { urls } from "./urls.js";
import { generatePath } from "react-router";
import AsyncStorage from "@callstack/async-storage";
import newMemberAppUrl from "./services/newMemberAppUrl.js";

export default class RouteActions {
  static pop() {
    setTimeout(() => {
      history.goBack();
    }, 1);
    return null;
  }
  static base(componentProps = {}) {
    history.push('', componentProps);
  }
  static createOrganization() {
    window.location.href = `${newMemberAppUrl}/createOrganization`;
  }
  static applyJob(componentProps = {}) {
    history.push(urls.applyJob, componentProps);
  }

  static signUpPassword(componentProps = {}) {
    history.push(urls.signUpPassword, componentProps);
  }

  static confirmPhone(componentProps = {}) {
    history.push(urls.confirmPhone, componentProps);
  }

  static confirmCode(componentProps = {}) {
    history.push(urls.confirmCode, componentProps);
  }

  static browseAll(id, user, componentProps = {}) {
    if (user && Object.keys(user).length == 0) {
      Object.assign(componentProps, { user: user });
      Object.assign(componentProps, { org: id });
      history.push(`${urls.browseAll}?org=${id}`, componentProps);
    } else {
      history.push(urls.browseAll, componentProps);
    }
  }

  static browseJobs(id, user, componentProps = {}) {
    if (user && Object.keys(user).length == 0) {
      Object.assign(componentProps, { user: user });
      Object.assign(componentProps, { org: id });
      history.push(`${urls.browseJobs}?org=${id}`, componentProps);
    } else {
      history.push(urls.browseJobs, componentProps);
    }
  }

  static browseDepartments(id, user, componentProps = {}) {
    if (user && Object.keys(user).length == 0) {
      Object.assign(componentProps, { user: user });
      Object.assign(componentProps, { org: id });
      history.push(`${urls.browseDepartments}?org=${id}`, componentProps);
    } else {
      history.push(urls.browseDepartments, componentProps);
    }
  }

  static browseEmployers(componentProps = {}) {
    history.push(urls.browseEmployers, componentProps);
  }

  static browseEmployer(urlParams, componentProps = {}) {
    const url = generatePath(urls.browseEmployer, {
      employerProfileLink: urlParams.customizableName,
    });
    history.push(url, componentProps);
  }

  static goEmployerProfile(urlParams) {
    if (urlParams.employerProfileLink) {
      window.open(urlParams.employerProfileLink, '_self');
    }
  }

  static goToBrowse(urlParams) {
    if (urlParams.employerProfileLink) {
      window.open(urlParams.employerProfileLink, '_self');
    }
  }

  static goToEmployerProfile(urlParams) {
    history.push(urlParams.employerProfileLink, {});
  }

  static browseJobDetail(urlParams, componentProps = {}) {
    const url = generatePath(urls.browseJob, { id: urlParams.id });
    history.push(url, componentProps);
  }

  static browseDepartmentDetail(urlParams, componentProps = {}) {
    const url = generatePath(urls.browseDepartment, {
      id: urlParams.id,
    });
    history.push(url, componentProps);
  }

  static browseAllJobDetail(urlParams, componentProps = {}) {
    const url = generatePath(urls.browseAllType, { type: 'jobs', id: urlParams.id });
    history.push(url, componentProps);
  }

  static browseAllDepartmentDetail(urlParams, componentProps = {}) {
    const url = generatePath(urls.browseAllType, {
      type: 'departments',
      id: urlParams.id,
    });
    history.push(url, componentProps);
  }
  static openingDetails(openingId, type) {
    history.push(`/job/${openingId}/${type}`);
  }

  static myOrganizationDetail(urlParams, componentProps = {}) {
    const url = generatePath(urls.myCompany, {
      organizationId: urlParams.organizationId,
    });
    history.push(url, componentProps);
  }

  static myContactDetail(urlParams, componentProps = {}) {
    const url = generatePath(urls.myContact, {
      contactId: urlParams.contactId,
    });
    history.push(url, componentProps);
  }

  static completeProfile(componentProps = {}) {
    history.push(urls.completeProfile, componentProps);
  }

  static contactProfile(componentProps = {}) {
    history.push(urls.contactProfile, componentProps);
  }

  static grantPermissions(componentProps = {}, replace = false) {
    history[replace ? 'replace' : 'push'](urls.grantPermissions, componentProps);
  }

  static home(componentProps = {}, replace = false) {
    window.location.href = `${newMemberAppUrl}/dashboard`;
  }

  static invitations(componentProps = {}, replace = false) {
    history[replace ? 'replace' : 'push'](urls.invitations, componentProps);
  }

  static myBoon(componentProps = {}) {
    history.push(urls.myBoon, componentProps);
  }

  static magicLink(componentProps) {
    history.push(urls.magicLinkSent, componentProps);
  }

  static waitForAccountApproval(componentProps) {
    history.push(urls.thankyouForYourInterest, componentProps);
  }

  static resetPassword(componentProps) {
    history.push(urls.resetPassword, componentProps);
  }

  static refer(componentProps) {
    history.push(urls.refer, componentProps);
  }

  static refresh(componentProps = {}) {
    history.replace(history.location.pathname, componentProps);
  }

  static replace(route, componentProps = {}) {
    history.replace(route, componentProps);
  }

  static jump(route, componentProps) {
    history.push(route, componentProps);
  }

  static signInEmail(componentProps = {}) {
    history.push(urls.signIn, componentProps);
  }

  static postLogin(componentProps = {}) {
    history.push(urls.postLogin, componentProps);
  }

  static signInSSO(componentProps = {}) {
    history.push(urls.signInSSO, componentProps);
  }

  static signInWithSSO(componentProps = {}) {
    history.push(urls.signInWithSSO, componentProps);
  }

  static login(componentProps = {}) {
    let url = history.location.pathname;
    try {
      AsyncStorage.setItem('before_login', url);
    } catch (error) {}

    history.push(urls.login, componentProps);
  }

  static signInPassword(componentProps) {
    history.push(urls.signInPassword, componentProps);
  }

  static signUp(componentProps = {}) {
    history.push(urls.signUp, componentProps);
  }

  static settings(componentProps = {}) {
    history.push(urls.settings, componentProps);
  }

  static tracker(componentProps = {}) {
    history.push(urls.tracker, componentProps);
  }

  static opportunityDetails(componentProps = {}) {
    const { jobId, referralId, requestId, direction, status } = componentProps;
    if (direction === 'their') {
      history.push(
        `${urls.opportunityDetails}?jobId=${jobId}&referralId=${referralId}&requestId=${requestId}&direction=${direction}&status=${status}`,
        {}
      );
    } else {
      history.push(`${urls.opportunityDetails}?jobId=${jobId}&direction=${direction}`, {});
    }
  }

  static changePassword(componentProps = {}) {
    history.push(urls.changePassword, componentProps);
  }

  static resetForgetPassword(componentProps) {
    history.push(urls.resetForgetPassword, componentProps);
  }

  static setPassword(componentProps) {
    history.push(urls.setPassword, componentProps);
  }

  static forgetPassword(componentProps) {
    history.push(urls.forgetPassword, componentProps);
  }

  static yourNetwork(componentProps = {}) {
    history.push(urls.yourNetwork, componentProps);
  }

  static userForm(componentProps = {}) {
    history.push(urls.userForm, componentProps);
  }

  static web(componentProps) {
    history.push(urls.web, componentProps);
  }

  static trackerSearch(componentProps = {}) {
    history.push(urls.trackerSearch, componentProps);
  }

  static notifications(componentProps = {}) {
    history.push(urls.notifications, componentProps);
  }

  static myContacts(componentProps = {}) {
    history.push(urls.myContacts, componentProps);
  }

  static myCompanies(componentProps = {}) {
    history.push(urls.myCompanies, componentProps);
  }

  static myProfile(componentProps = {}) {
    history.push(urls.myProfile, componentProps);
  }

  static mySettings(componentProps = {}) {
    history.push(urls.mySettings, componentProps);
  }

  static manageAccounts(componentProps = {}) {
    history.push(urls.manageAccounts, componentProps);
  }

  static myStats(componentProps = {}) {
    history.push(urls.myStats, componentProps);
  }

  static successScreen(componentProps = {}) {
    history.push(urls.successScreen, componentProps);
  }

  static recommendations(componentProps = {}) {
    history.push(urls.recommendations, componentProps);
  }

  static yourNetworkInvite(componentProps = {}) {
    history.push(urls.networkInvite, componentProps);
  }

  static yourNetworkConnect(componentProps = {}) {
    history.push(urls.networkConnect, componentProps);
  }

  static followCompanies(componentProps = {}) {
    history.push(urls.followCompanies, componentProps);
  }
  static sentJobs(componentProps = {}) {
    history.push(urls.sentJobs, componentProps);
  }
  static signUpLocation(componentProps = {}) {
    history.push(urls.location, componentProps);
  }
  static signUpRequestAccess(componentProps = {}) {
    history.push(urls.requestAccess, componentProps);
  }
  static referDetail(componentProps = {}) {
    history.push(urls.referDetail, componentProps);
  }
  static publicReferral(componentProps = {}) {
    history.push(urls.publicReferral, componentProps);
  }
  static referredJobPost(componentProps = {}) {
    let id;
    if (componentProps.extra) {
      id = componentProps.extra.refer_request.id;
    } else {
      id = componentProps.refer_request && componentProps.refer_request.id;
    }
    history.push(urls.referredJobPost, { referRequestId: id });
  }
  static referDecline(componentProps = {}) {
    history.push(urls.jobDecline, componentProps);
  }
  static publicJobApplication(componentProps = {}) {
    history.push(urls.publicJobApplication, componentProps);
  }
  static dashboardPublicJobApplication(componentProps = {}) {
    history.push(urls.dashboardPublicJobApplication, componentProps);
  }
  static userAppDashboard(componentProps = {}) {
    localStorage.removeItem('activeTab');
    window.location.href = `${newMemberAppUrl}/dashboard`;
  }
  static userAppBrowse(componentProps = {}) {
    localStorage.removeItem('activeTab');
    window.location.href = `${newMemberAppUrl}/browse`;
  }
  static userAppTracker(componentProps = {}) {
    localStorage.removeItem('activeTab');
    window.location.href = `${newMemberAppUrl}/tracker`;
  }
}
