import { each, isEmpty } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dimensions, Image, Linking, Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import { withGetScreen } from 'react-native-getscreen';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { connect } from 'react-redux';
import { openOrgDrawer } from '../../DrawerActions.js';
import Permissions from '../../wrappers/permission/permission';
import PushNotification from '../../wrappers/pushNotification/pushNotification';

//components
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import Text from '../../components/Text/Text';

// import ComingSoon from '../ComingSoon/comingSoon';

//utils
import { notification, permissionsInterval } from '../../../src/helperFunctions';
import respStyles from '../../responsiveStyles';
import Activity from './components/Activity/Activity';
import Leaderboard from './components/Leaderboard/Leaderboard';
import QuickLinks from './components/QuickLinks/QuickLinks';
import RewardsTermsAndConditions from './components/RewardsTermsAndConditions/RewardsTermsAndConditions';
import Stats from './components/Stats/Stats';
import styles from './styles';

import ProfileCompleteBar from '../../components/ProfileCompleteBar/ProfileCompleteBar';

//images
import filterIcon from '../../assets/img/filter_icon_black.png';

//actions
import * as networkActions from '../../globalActions/networkActions';
import * as notificationsActions from '../Notifications/actions';
import * as actions from './actions';
import * as activityActions from './components/Activity/actions';
import * as statsActions from './components/Stats/actions';

//styles
import { dashboardButton, paddingLeftRightTwenty } from '../../generalStyles';
import * as trackerActions from '../Tracker/actions';

const initialLayout = {
  height: 0,
  width: Dimensions.get('window').width,
};

const renderScene = SceneMap({
  dashboard: () => <Activity />,
  stats: Stats,
  leaderboard: Leaderboard,
  termsAndConditions: RewardsTermsAndConditions,
});

const Home = (props) => {
  const {
    selectedOrganization,
    getActivityList,
    currentUserId,
    getNewReferralsCount,
    getNotificationsList,
    getCurrentUserStats,
    currentSelectedOrg,
    initTabIndex,
    permissions,
    changeUserPermission,
    isMobile,
    isTablet,
    params,
    adminAppUrl,
    location,
    openDrawer,
    onEnterFlag,
    postUserContacts,
    organizations,
  } = props;
  const scrollRef = useRef(null);
  const [index, setIndex] = useState(initTabIndex || 0);
  const [isOpen, setIsOpen] = useState(false);
  const [referInitialValues, setReferInitialValues] = useState({});
  const [showLeaderBoard, setShowLeaderBoard] = useState(true);
  const [routes, setRoutes] = useState([
    { key: 'dashboard', title: 'For You' },
    { key: 'stats', title: 'Stats' },
    { key: 'leaderboard', title: 'Leaderboard' },
    { key: 'termsAndConditions', title: 'Terms and Conditions' },
  ]);
  const web = Platform.OS === 'web';
  const tabScreen = !web || isMobile() || isTablet();

  const showPublicLink = useMemo(() => {
    if (currentSelectedOrg) {
      const currOrg = organizations.find((org) => org.organization.id === currentSelectedOrg.id);
      if (currentSelectedOrg.label === 'Boon' || (currOrg && currOrg.organization.show_public_link)) {
        return true;
      }
    }
    return false;
  }, [currentSelectedOrg, organizations]);

  useEffect(() => {
    if (currentSelectedOrg) {
      const currOrg = organizations.find((org) => org.organization.id === currentSelectedOrg.id);
      if (currOrg) {
        setShowLeaderBoard(currOrg.organization.show_leader_board);
      }
    }
  },[currentSelectedOrg, organizations])

  useEffect(() => {
    if (
      !isEmpty(params) &&
      typeof params === 'string' &&
      params.includes('modal') &&
      params.includes('refer_token') &&
      params.includes('archive')
    ) {
      if (params.includes('archive')) {
        Linking.openURL(`${adminAppUrl}/tracker/archive${params}`);
      } else {
        Linking.openURL(`${adminAppUrl}/tracker${params}`);
      }
    }
    getNewReferralsCount();
    getNotificationsList();
  }, []);

  useEffect(() => {
    if (currentUserId) {
      getCurrentUserStats();
    }
  }, [currentUserId]);

  useEffect(() => {
    const serachQuery = new URLSearchParams(location.search);
    const provider = serachQuery.get('provider');

    if (provider) {
      notification.ref.show({ message: `${provider} connected succesfully` });
    }
    permissionsInterval.interval = setTimeout(checkUserPermissions, 2000);
    PushNotification.configure();

    postUserContacts();
  }, []);

  // static onEnter() {
  //     Actions.refresh({
  //         onEnterFlag: new Date()
  //     });
  // }

  const handleIndexChange = (i) => {
    if (i === 1) getCurrentUserStats();
    setIndex(i);
  };

  const checkUserPermissions = () => {
    Permissions.checkMultiple(['contacts', 'notification', 'location'])
      .then((response) => {
        each(response, (permissionValue, permissionKey) => {
          const valueToBoolean = permissionValue === 'authorized';
          if (permissions[permissionKey] !== valueToBoolean) {
            changeUserPermission({ [permissionKey]: valueToBoolean });
          }
        });
      })
      .catch((error) => {
        console.log('permissions check failed');
      });
  };

  const renderFilterIcon = () => {
    if (isMobile() || isTablet()) {
      return [
        <TouchableOpacity key="icon" onPress={() => openDrawer()} style={[dashboardButton.button]}>
          <Image source={filterIcon} resizeMode="contain" style={[dashboardButton.image, styles.filterButtonImage]} />
        </TouchableOpacity>,
      ];
    }
  };

  const renderTabedView = useMemo(() => {
    return (
      <TabView
        navigationState={{
          index: index,
          routes: routes,
          isOpen: isOpen,
          referInitialValues: referInitialValues,
        }}
        renderScene={renderScene}
        renderTabBar={(props) => (
          <React.Fragment>
            <TabBar
              {...props}
              indicatorStyle={{ backgroundColor: '#0fbc71' }}
              style={styles.tabBar}
              getLabelText={({ route }) => route.title}
              labelStyle={{ fontSize: 13, color: '#9b9b9b' }}
            />
            <ProfileCompleteBar />
          </React.Fragment>
        )}
        onIndexChange={handleIndexChange}
        initialLayout={initialLayout}
      />
    );
  }, [initialLayout, index]);

  const renderNonTabedView = useMemo(() => {
    return (
      <ScrollView ref={scrollRef} style={[Platform.OS === 'web' && { height: '100%' }]}>
        <ProfileCompleteBar />
        <View style={[styles.root, respStyles.flexRowReverse, respStyles.flexWrap, respStyles.justifyCenter]}>
          <View style={styles.rightSide}>
            {showPublicLink && (
              <View>
                <Text style={styles.untabbedTitles}>Quick Links</Text>
                <QuickLinks />
              </View>
            )}
            <View>
              <Text style={styles.untabbedTitles}>Stats</Text>
              <Stats withoutOrganization />
            </View>
            {showLeaderBoard && (
              <View>
                <Leaderboard
                  screenWrapperStyles={paddingLeftRightTwenty}
                  titlesWrapperStyles={{ paddingLeft: 0 }}
                  untabbedTitles={styles.untabbedTitles}
                />
              </View>
            )}
            <View>
              <RewardsTermsAndConditions showLeaderBoard={showLeaderBoard} />
            </View>
          </View>
          <View style={styles.leftSide}>
            <Text style={styles.untabbedTitles}>Activity Feed</Text>
            <Activity withoutTabs />
          </View>
        </View>
      </ScrollView>
    );
  }, [showPublicLink, showLeaderBoard]);

  return (
    <DashboardBase
      title={currentSelectedOrg ? currentSelectedOrg.label : ''}
      // renderLeftBlock={renderFilterIcon()}
    >
      {tabScreen ? renderTabedView : renderNonTabedView}
    </DashboardBase>
  );
};

const mapStateToProps = (state) => {
  const organizationsWhereImMember = state.organizationsReducer.userOrganizations.filter((organization) => {
    return organization.role !== null && organization.status === 1;
  });

  return {
    organizations: organizationsWhereImMember,
    actionSheetOptions: [
      'Dashboard',
      ...organizationsWhereImMember.map((organizationObj) => organizationObj.organization.name),
      'Cancel',
    ],
    selectedOrganization: state.activityReducer.selectedOrganization,
    permissions: state.authReducer.permissions,
    unreadNotifications: state.notificationsReducer.unreadNotifications,
    adminAppUrl: state.authReducer.adminAppUrl,
    currentSelectedOrg: state.authReducer.currentSelectedOrg,
    currentUserId: state.authReducer.currentUser.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationsList: (params) => {
      dispatch(notificationsActions.getNotificationsList(params));
    },
    getNewReferralsCount: () => {
      dispatch(trackerActions.getNewReferralsCount());
    },
    getCurrentUserStats: () => {
      dispatch(statsActions.getCurrentUserStats());
    },
    getActivityList: (params) => {
      dispatch(activityActions.getActivityList(params));
    },
    changeSelectedOrganization: (index, actionSheetOptions, organizations) => {
      if (index === 0) {
        dispatch(actions.changeSelectedOrganization({ name: 'Dashboard' }));
      } else if (index !== actionSheetOptions.length - 1) {
        dispatch(actions.changeSelectedOrganization(organizations[index - 1].organization));
      }
    },
    changeUserPermission: (changedPermission) => {
      dispatch(actions.changeUserPermission(changedPermission));
    },
    postUserContacts: () => {
      dispatch(networkActions.postUserContacts());
    },
    openDrawer: () => {
      dispatch(openOrgDrawer());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Home));
