import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { withGetScreen } from 'react-native-getscreen';
import _debounce from 'lodash/debounce';

import Actions from '../../../../RouteActions';
import { isEmpty } from 'lodash-es';
import { getAllBySort } from './actions';
import { Dimensions } from 'react-native';

//actions
import * as actions from './actions';
import { openBrowseFiltersDrawer } from '../../../../DrawerActions';
import { getEmployer } from '../../../EmployerProfile/actions';

//components
import DepartmentListElement from '../../../../components/DepartmentListElement/DepartmentListElement';
import JobListElement from '../../../../components/JobListElement/JobListElement';
import Pagination from '../../../../components/Pagination/Pagination';

import * as companiesService from "../../../../services/organizationsService";
import newMemberAppUrl from '../../../../services/newMemberAppUrl';

import OpeningDetail from './OpeningDetail';
import { urls } from '../../../../urls';

const Per_Page = 50;
const BrowsePageStyle = {
  mobileContainer: {
    flexWrap: 'wrap',
  },

  webContainer: {
    height: '100%',
    flexWrap: 'nowrap',
  },

  mobileJobContainer: {
    width: '100%',
    height: '100%',
  },

  webJobContainer: {
    width: 375,
    maxWidth: 375,
  },
};

const TYPE_ACRONYMS = {
  all: 'openings',
  job: 'jobs',
  department: 'departments',
};

const BrowseList = (props) => {
  const {
    type,
    params,
    listLoading,
    list,
    organization,
    browseFilters,
    filteredResult,
    searchedQuery,
    getAll,
    isMobile,
    isTablet,
    locationQuery,
  } = props;
  const browseFiltersRef = useRef(undefined);
  const searchedQueryRef = useRef(undefined);
  const locationRef = useRef(undefined);
  const currentId = isEmpty(params) ? list && list.result && list.result[0] && list.result[0].id : params.id;
  const data = (list && list.result) || [];

  const isMobileScreen = isMobile();
  const fetchMore = useCallback(
    _debounce((page, per_page, init = false, selectFirst) => {
      getAll(
        {
          type,
          filters: {
            ...(browseFiltersRef.current || {}),
          },
          search_term: searchedQueryRef.current || undefined,
          page: page,
          per_page: per_page,
          location: locationRef.current || null,
          selectFirstOpening: !params.id || selectFirst,
        },
        organization,
        init
      );
    }, 10),
    []
  );

  useEffect(() => {
    browseFiltersRef.current = browseFilters;
    searchedQueryRef.current = searchedQuery;
    locationRef.current = locationQuery;
    fetchMore(1, Per_Page, false);
  }, [browseFilters, searchedQuery, locationQuery]);

  useEffect(() => {
    if (props.location && props.location.search) {
      companiesService
        .getOrganizationDetailsByGeneralSettingId(/[?|&]org=([^&]+)/.exec(props.location.search)[1])
        .then((response) => {
          if (response.data.payload.organization) {
            getAll(
              {
                type,
                filters: {
                  ...(browseFiltersRef.current || {}),
                },
                search_term: searchedQueryRef.current || undefined,
                page: 1,
                per_page: Per_Page,
                current_job_id: currentId,
              },
              response.data.payload.organization,
              false
            );
          }
        });
    }
  }, [props.location, currentId]);

  const renderDetail = useMemo(() => {
    if (params.id) {
      return params.type === 'jobs' || type === 'job' ? <OpeningDetail job={true} /> : <OpeningDetail job={false} />;
    }

    const firstItem = data && data[0];
    if (firstItem) {
      if (firstItem.category === 'job') {
        return <OpeningDetail firstJob={firstItem} job={true} />;
      }
      if (firstItem.category === 'department') {
        return <OpeningDetail firstJob={firstItem} job={false} />;
      }
    }
    return null;
  }, [data, params]);

  const handleClickListItem = (item) => {
    if (item.category === 'department') {
      if (type === 'all') {
        if (isMobileScreen) {
          Actions.openingDetails(item.uuid, 'Department Detail');
        } else {
          Actions.browseAllDepartmentDetail({ id: item.uuid });
        }
      } else {
        if (isMobileScreen) {
          Actions.openingDetails(item.uuid, 'Department Detail');
        } else {
          Actions.browseDepartmentDetail({ id: item.uuid });
        }
      }
    } else if (item.category === 'job') {
      if (type === 'all') {
        if (isMobileScreen) {
          Actions.openingDetails(item.uuid, 'Job Detail');
        } else {
          Actions.browseAllJobDetail({
            id: item.uuid,
            organization_id: organization && organization.id,
          });
        }
      } else {
        if (isMobileScreen) {
          Actions.openingDetails(item.uuid, 'Job Detail');
        } else {
          Actions.browseJobDetail({ id: item.uuid });
        }
      }
    }
  };

  const renderListElement = (item) => {
    if (item.category === 'department') {
      return (
        <DepartmentListElement
          department={item}
          listItem={true}
          currentDepartmentId={currentId}
          onPress={() => handleClickListItem(item)}
        />
      );
    }
    if (item.category === 'job') {
      return (
        <JobListElement
          job={item}
          listItem={true}
          currentJobId={currentId}
          onPress={() => handleClickListItem(item)}
          from="browse"
        />
      );
    }
    return null;
  };

  const currentScreenSize = useCallback(() => {
    return Math.round(Dimensions.get('window').width) < 750;
  }, [Dimensions.get('window').width]);

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      {!listLoading && (!data || !data.length) ? (
        <Box sx={{ marginTop: '12px' }}>
          <Typography variant="body1" style={{ color: '#000000', fontSize: 16 }}>
            No {TYPE_ACRONYMS[type]} are available yet
          </Typography>
        </Box>
      ) : (
        <Grid container style={currentScreenSize() ? BrowsePageStyle.mobileContainer : BrowsePageStyle.webContainer}>
          <Grid item style={currentScreenSize() ? BrowsePageStyle.mobileJobContainer : BrowsePageStyle.webJobContainer}>
            <Box
              sx={{
                height: 'calc(100% - 10px)',
                paddingTop: '10px',
                overflowY: 'auto',
                overflowX: 'hidden',
                position: 'relative',
              }}
            >
              <List disablePadding>
                {data.map((item) => (
                  <ListItem key={item.id} className="no-padding column align-stretch">
                    {renderListElement(item)}
                  </ListItem>
                ))}
              </List>
              {listLoading && list.page_info.page === 1 ? null : (
                <Box sx={{ marginTop: 12 }}>
                  <Pagination
                    page={list.page_info.page}
                    count={list.page_info.pages}
                    disabled={listLoading}
                    onChange={(p) => fetchMore(p, Per_Page, false, true)}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs style={{ height: '100%', maxWidth: 'calc(100% - 375px)' }}>
            <Box
              sx={{
                height: 'calc(100% - 10px)',
                padding: '10px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {!isMobileScreen && renderDetail}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  let currOrgId;
  const checkUser = Object.keys(state.authReducer.currentUser);
  if (checkUser.length > 1) {
    currOrgId = state.authReducer.currentSelectedOrg.id;
  } else {
    currOrgId = state.organizationsReducer.organizationDetails.id;
  }
  const filteredResult = state.suggestionsReducer.globalSearch.suggestions.map((i) => {
    return i.data.filter((item) => item.organization_id === currOrgId);
  });
  return {
    list: state.browseReducer.list,
    listLoading: state.browseReducer.listLoading,
    browseFilters: state.browseReducer.filters,
    filteredResult,
    currentUser: state.authReducer.currentUser,
    organization: state.organizationsReducer.organizationDetails,
    searchedQuery: state.suggestionsReducer.queryString,
    locationQuery: state.suggestionsReducer.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAll: (params = { page: 1, per_page: 50 }, organization = null, init = false) => {
      dispatch(actions.getAll(params, organization, init));
    },
    getAllBySort: (sort_by, queryString, filters) => {
      if (queryString && queryString.length > 0) {
        dispatch(
          getSuggestions(queryString, 'globalSearch', 'results', null, null, 'globalSearch', 'jobs', sort_by, filters)
        );
      } else {
        dispatch(getAllBySort(sort_by, filters));
      }
    },
    openFilterDrawer: () => {
      dispatch(openBrowseFiltersDrawer());
    },
    getEmployer: (id) => {
      dispatch(getEmployer(id));
    },
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withGetScreen(BrowseList));
