import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, TouchableOpacity, Image } from 'react-native';
import WorkOutline from '@material-ui/icons/WorkOutline';
import GroupOutlined from '@material-ui/icons/GroupOutlined';
import { sortBy } from 'lodash-es';

//components
import AutoSuggestComponent from '../../components/AutoSuggestComponent/AutoSuggestComponent';
import Text                 from '../../components/Text/Text';

//utils
import {locationObjToStr} from '../../helperFunctions';

//styles
import styles        from './styles';
import {openingsListElement, common as commonStyles} from '../../generalStyles';
import defaultJobAvatar from '../../assets/img/default_org_avatar.png';

class SelectPosition extends Component {
    constructor(props) {
        super(props);
        this.renderSuggestElement = this.renderSuggestElement.bind(this);
    }

    renderSuggestElement({item}) {
        const { onSelectPosition, generalReferrals } = this.props;
        let sortedOpenings = sortBy([...item.jobs], ['title']);
        if (generalReferrals) {
            sortedOpenings.push({
                title: 'General Referral',
                category: 'general',
            });
        }
        return (
            <View>
                {
                    sortedOpenings.map((job, index) => {
                        const image = job.logo_image_url ? {uri: job.logo_image_url} : defaultJobAvatar;
                        return (
                            <View key={`job-${index}`}>
                                <TouchableOpacity style={openingsListElement.listItem} onPress={() => onSelectPosition(job)}>
                                    <View style={openingsListElement.listItemBody}>
                                        <View style={openingsListElement.imageWrapper}>
                                            <Image style={openingsListElement.image} source={image} resizeMode="contain" />
                                        </View>
                                        <View style={openingsListElement.info}>
                                            <View style={[openingsListElement.jobInfoWrapper]}>
                                                <Text style={[openingsListElement.title, styles.jobTitle]} numberOfLines={1}>{job.title}</Text>
                                                {job.category === 'job' && <WorkOutline style={{color: "#444444", opacity: "0.6", marginLeft: 9, width: 14}} />}
                                                {job.category === 'department' && <GroupOutlined style={{color: "#444444", opacity: "0.6", marginLeft: 9, width: 14}} />}
                                            </View>
                                            {Boolean(job.location) && <Text style={openingsListElement.description} numberOfLines={1}>{locationObjToStr(job.location)}</Text>}
                                        </View>
                                    </View>
                                    <View>
                                        {job.category === 'department' && (
                                            <GroupOutlined style={{fontSize: 60, color: '#f8f8f8'}} />
                                        )}
                                    </View>
                                </TouchableOpacity>
                                <View style={openingsListElement.hr}>
                                </View>
                            </View>
                        )
                    })
                }

            </View>
        )
    }

    render() {
        const { organizationId, page, referSearch, showAllOnLoad } = this.props;
        return (
            <AutoSuggestComponent
                skipNavOnBackPress={this.props.skipNavOnBackPress}
                onBackButtonPress={this.props.onBackButtonPress}
                suggestElement={this.renderSuggestElement}
                suggestHeader="Recent Openings"
                service="autocompleteJobs"
                params={{'organization_id': organizationId, 'referSearch': referSearch}}
                suggestionsKey="jobs"
                keyField="industry"
                wrapperStyle={styles.wrappedStyle}
                searchType="jobSearch"
                placeholder='Search Openings'
                referDrawer={this.props.referDrawer}
                page={page}
                showAllOnLoad
                disabled
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        generalReferrals: state.organizationsReducer.generalReferrals
    };
};

export default connect(mapStateToProps, null)(SelectPosition);
