import React from 'react';
import BoonTextField from '../Basic/BoonTextField';

const extractLinkedInUsername = (value) => {
  if (value) {
    const cleanedValue = value.trim().replace(/^\/+|\/+$/g, '');

    const withoutInPrefix = cleanedValue.replace(/^in\//, '');

    const linkedinUrlPattern = /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/?(?:in\/)?([a-zA-Z0-9-]+)$/;
    const match = withoutInPrefix.match(linkedinUrlPattern);

    if (match) {
      return match[1];
    }

    const usernamePattern = /^[a-zA-Z0-9-]+$/;
    const usernameMatch = withoutInPrefix.match(usernamePattern);

    if (usernameMatch) {
      return usernameMatch[0];
    }

    return cleanedValue;
  } else {
    return value;
  }
};

export default function FormInputNew(props) {
  const { input, name, ...inputProps } = props;

  const transformedValue = name === "linkedin_link" && input.value ? extractLinkedInUsername(input.value) : input.value;

  return (
    <BoonTextField
      {...inputProps}
      onChange={input.onChange}
      value={transformedValue}
      onBlur={(e) => {
        props.setNameWarning && props.setNameWarning(e.target.value.length === 0);
      }}
    />
  );
}
